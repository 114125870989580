<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t('orderMsgs.orders') }}
              </h4>
            </div>

            <div class="text-right">
              <a
                href="#"
                class="btn btn-primary mr-2"
                v-if="getLoggedInUser.role === USER_ROLES.ADMIN"
                @click.prevent="openOrderResultsModal()"
              >
                {{ $t('orderMsgs.exportReport') }}
              </a>

              <router-link :to="{ name: 'add-order' }" class="btn btn-primary">
                {{ $t('orderMsgs.addOrder') }}
              </router-link>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <div class="d-flex align-items-center mb-2">
                <div class="mm-search-bar col-sm-6 col-md-6 px-1">
                  <div class="searchbox">
                    <a class="search-link" href="#"><i class="fas fa-search"></i></a>
                    <input
                      type="text"
                      class="form-control search-input"
                      v-model="searchTerm"
                      :placeholder="`${$t('orderMsgs.searchOrders')}...`"
                      @input="onSearch"
                    />
                  </div>
                </div>

                <b-button variant="link" pill @click.prevent="showFilters = true" class="ml-3 p-0" v-if="!showFilters">
                  <b-badge><i class="fas fa-sliders-h mr-2" />{{ $t('generalMsgs.showFilters') }} </b-badge>
                </b-button>

                <b-button variant="link" class="ml-3 p-0" pill @click.prevent="showFilters = false" v-if="showFilters">
                  <b-badge variant="light"
                    ><i class="fas fa-eye-slash mr-2" />{{ $t('generalMsgs.hideFilters') }}</b-badge
                  >
                </b-button>

                <b-button variant="link" class="ml-3 p-0" pill @click.prevent="clearFilters" v-if="areFiltersApplied">
                  <b-badge variant="light"
                    ><i class="fas fa-times" />
                    {{ $t('clearFilters') }}
                  </b-badge>
                </b-button>
              </div>
              <transition name="slide">
                <div v-if="showFilters" class="mt-2">
                  <div class="row mb-2 mx-0 w-100">
                    <div class="col-sm-6 col-md-6 mb-2 px-1">
                      <v-select
                        id="customer"
                        class="form-control v-select-custom"
                        style="height: 40px"
                        label="name"
                        v-model="customer"
                        :reduce="(customer) => customer.id"
                        :placeholder="$t('customerMsgs.customer')"
                        :options="allCustomers"
                        :loading="areCustomersLoading"
                        :filterBy="filterCustomers"
                        @input="onFilterApplied"
                      >
                        <template #search="{ attributes, events }">
                          <input class="vs__search" v-bind="attributes" v-on="events" />
                        </template>

                        <template slot="option" slot-scope="option">
                          {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </div>

                    <div class="col-sm-6 col-md-6 mb-2 px-1">
                      <v-select
                        id="creator"
                        class="form-control v-select-custom"
                        style="height: 40px"
                        label="email"
                        v-model="creator"
                        :reduce="(creator) => creator.id"
                        :placeholder="$t('userMsgs.creator')"
                        :options="allCreators"
                        :loading="areCreatorsLoading"
                        :filterBy="filterCreators"
                        @input="onFilterApplied"
                      >
                        <template #search="{ attributes, events }">
                          <input class="vs__search" v-bind="attributes" v-on="events" />
                        </template>

                        <template slot="option" slot-scope="option">
                          {{ option.first_name }} {{ option.last_name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.first_name }} {{ option.last_name }}
                        </template>
                      </v-select>
                    </div>

                    <div class="col-sm-6 col-md-4 mb-2 px-1">
                      <date-picker
                        id="deliveredDate"
                        class="form-control datepicker-custom"
                        :placeholder="$t('deliveryNoteMsgs.dateDelivered')"
                        v-model="deliveredDateRange"
                        range
                        :lang="lang"
                        format="DD.MM.YYYY"
                        valueType="YYYY-MM-DD"
                        @pick="onFilterApplied"
                        @clear="onFilterApplied"
                      />
                    </div>

                    <div class="col-sm-6 col-md-4 mb-2 px-1">
                      <date-picker
                        id="invoicedDate"
                        class="form-control datepicker-custom"
                        :placeholder="$t('orderMsgs.invoiceDate')"
                        v-model="invoicedDateRange"
                        range
                        :lang="lang"
                        format="DD.MM.YYYY"
                        valueType="YYYY-MM-DD"
                        @pick="onFilterApplied"
                        @clear="onFilterApplied"
                      />
                    </div>

                    <div class="col-sm-6 col-md-4 mb-2 px-1">
                      <b-form-select
                        id="status"
                        class="form-control form-control-b-select"
                        v-model="status"
                        :options="allStatusOptions"
                        @input="onFilterApplied"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled>{{ $t('status') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </transition>

              <b-table
                :fields="tableColumns"
                :items="allOrders"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
                @row-clicked="openOrderDetailsModal"
              >
                <template #cell(order_number)="data">
                  <strong>
                    {{ data.item.order_number }}
                  </strong>
                </template>

                <template #cell(customer__name)="data">
                  {{ data.item.customer.name }}
                </template>

                <template #cell(cost_center__center_number)="data">
                  {{ data.item.cost_center.center_number }}
                </template>

                <template #cell(construction_project)="data">
                  <span v-if="data.item.construction_project">
                    {{ data.item.construction_project }}
                  </span>
                  <span v-else>--</span>
                </template>

                <template #cell(sheet_numbers)="data">
                  {{ truncateSheetNums(data.item.sheet_numbers) }}
                </template>

                <template #cell(labor_hours)="data">
                  {{ $n(data.item.labor_hours, 'number', 'de-DE') }}
                </template>

                <!-- Revenue & Cost columns hidden from orders list. -->
                <!-- <template #cell(revenue)="data">
                  <span v-if="data.item.revenue">
                    {{ $n(data.item.revenue, "currency", "de-DE") }}
                  </span>
                  <span v-else>--</span>
                </template>
                <template #cell(cost)="data">
                  <span v-if="data.item.cost">
                    {{ $n(data.item.cost, "currency", "de-DE") }}
                  </span>
                  <span v-else>--</span>
                </template> -->

                <template #cell(deliveryNote)="data">
                  <span v-if="get(deliveryNotesByOrder, data.item.id)">
                    {{ deliveryNotesByOrder[data.item.id].delivery_note_number }}
                  </span>
                  <span v-else>{{ isDelNoteLoading ? '...' : '--' }}</span>
                </template>

                <template #cell(delivery_date)="data">
                  <span v-if="get(deliveryNotesByOrder, data.item.id)">
                    {{ formatDate(deliveryNotesByOrder[data.item.id].date) }}
                  </span>
                  <span v-else>{{ isDelNoteLoading ? '...' : '--' }}</span>
                </template>

                <template #cell(invoice__invoice_date)="data">
                  <span v-if="get(invoicesByOrder, data.item.id)">
                    {{ formatDate(invoicesByOrder[data.item.id].invoice_date) }}
                  </span>
                  <span v-else>{{ areInvoicesLoading ? '...' : '--' }}</span>
                </template>

                <template #cell(received_date)="data">
                  {{ formatDate(data.item.received_date) }}
                </template>

                <!-- Created By column hidden from orders list. -->
                <!-- <template #cell(createdBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                  <span v-else>--</span>
                </template> -->

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data" style="min-width: 50px">
                  <a
                    href="#"
                    @click.prevent="openUsedMaterialsModal(data.item)"
                    class="mr-2"
                    tabindex="0"
                    v-b-popover.hover.top
                    :title="$t('materialMsgs.manageMaterials')"
                  >
                    <i class="fas fa-cubes text-secondary" />
                  </a>

                  <a
                    href="#"
                    @click.prevent="openPostInvoiceModal(data.item)"
                    class="mr-2"
                    tabindex="0"
                    v-b-popover.hover.top
                    :title="$t('invoiceMsgs.manageInvoice')"
                  >
                    <i class="fas fa-file-invoice-dollar text-secondary" />
                  </a>

                  <a
                    href="#"
                    @click.prevent="openPostDeliveryNoteModal(data.item)"
                    class="mr-2"
                    tabindex="0"
                    v-b-popover.hover.top
                    :title="$t('deliveryNoteMsgs.manageDeliveryNote')"
                  >
                    <i class="fas fa-receipt text-secondary" />
                  </a>

                  <router-link
                    :to="{
                      name: 'edit-order',
                      params: { id: data.item.id },
                    }"
                    class="svg-icon mr-2"
                    v-b-popover.hover.top
                    :title="$t('edit')"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link>

                  <a
                    href="#"
                    @click.prevent="confirmDeleteOrder(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <div class="d-flex justify-content-end">
                <b-form-select
                  class="form-control form-control-b-select mr-2"
                  style="height: 38px; width: 5rem"
                  v-model="pageSize"
                  :options="perPageChoices"
                  @input="onPerPageChange"
                >
                  <template #first>
                    <b-form-select-option :value="-1" disabled>
                      {{ $t('orderMsgs.ordersPerPage') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <b-pagination
                  :value="currentPage"
                  :total-rows="totalOrders"
                  :per-page="pageSize || totalOrders"
                  @change="onPageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <post-delivery-note-modal
      :showModal.sync="modals.showPostDeliveryNoteModal"
      :orderId="modals.selectedOrderId"
      :order="modals.selectedOrder"
      @close="hidePostDeliveryNoteModal"
    />

    <post-invoice-modal
      :showModal.sync="modals.showPostInvoiceModal"
      :orderId="modals.selectedOrderId"
      :order="modals.selectedOrder"
      @close="hidePostInvoiceModal"
    />

    <used-material-list-modal
      :showModal.sync="modals.showUsedMaterialsModal"
      :orderId="modals.selectedOrderId"
      :order="modals.selectedOrder"
      @close="hideUsedMaterialsModal"
    />

    <order-detail-modal
      :showModal.sync="modals.showOrderDetailsModal"
      :orderId="modals.selectedOrderDetailsId"
      :orderNum="modals.selectedOrderNum"
      :selectedTabIndex="0"
      @close="hideOrderDetailsModal"
    />

    <order-results-modal :showModal.sync="modals.showOrderResultsModal" @close="hideOrderResultsModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';
import { USER_ROLES } from '../../common/constants';
import { formatDate } from '../../common/utils';
import PostDeliveryNoteModal from './DeliveryNotes/PostDeliveryNoteModal';
import PostInvoiceModal from './Invoices/PostInvoiceModal';
import UsedMaterialListModal from './UsedMaterials/UsedMaterialListModal';
import { get, keyBy, debounce, truncate } from 'lodash';
import OrderDetailModal from './OrderDetail/OrderDetailModal';
import OrderResultsModal from './OrderResults/OrderResutsModal';

export default {
  name: 'OrderList',

  components: {
    DatePicker,
    PostDeliveryNoteModal,
    PostInvoiceModal,
    UsedMaterialListModal,
    OrderDetailModal,
    OrderResultsModal,
    vSelect,
  },

  data() {
    return {
      allOrders: [],
      allCustomers: [],
      allCreators: [],
      currentPage: 1,
      pageSize: 20,
      totalOrders: 0,

      showFilters: false,
      customer: null,
      creator: null,
      status: null,
      deliveredDateRange: null,
      invoicedDateRange: null,

      deliveryNotes: [],
      deliveryNotesByOrder: {},
      invoices: [],
      invoicesByOrder: {},

      isLoading: false,
      isCostLoading: false,
      isDelNoteLoading: false,
      areInvoicesLoading: false,
      areCustomersLoading: false,
      areCreatorsLoading: false,
      searchTerm: '',
      ordering: null,
      USER_ROLES,

      modals: {
        selectedOrder: null,
        selectedOrderId: null,
        selectedOrderNum: null,
        showPostDeliveryNoteModal: false,
        showPostInvoiceModal: false,
        showUsedMaterialsModal: false,
        showOrderDetailsModal: false,
        showOrderResultsModal: false,
        selectedOrderDetailsId: null,
      },

      perPageChoices: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 30, text: '30' },
        { value: 50, text: '50' },
        { value: null, text: 'All' },
      ],
    };
  },

  methods: {
    ...mapActions([
      'getAllCustomers',
      'getAllOrders',
      'deleteOrder',
      'getOrdersCosts',
      'getOrdersDeliveryNotes',
      'getOrdersInvoices',
      'getAllUsers',
    ]),
    get,
    formatDate,

    openPostDeliveryNoteModal(order) {
      this.modals.selectedOrder = order;
      this.modals.selectedOrderId = order.id;
      this.modals.showPostDeliveryNoteModal = true;
    },

    hidePostDeliveryNoteModal() {
      this.modals.showPostDeliveryNoteModal = false;
      this.fetchAndSetOrders(this.currentPage);
      this.modals.selectedOrderId = null;
      this.modals.selectedOrder = null;
    },

    openPostInvoiceModal(order) {
      this.modals.selectedOrder = order;
      this.modals.selectedOrderId = order.id;
      this.modals.showPostInvoiceModal = true;
    },

    hidePostInvoiceModal() {
      this.modals.showPostInvoiceModal = false;
      this.fetchAndSetOrders(this.currentPage);
      this.modals.selectedOrderId = null;
      this.modals.selectedOrder = null;
    },

    openUsedMaterialsModal(order) {
      this.modals.selectedOrder = order;
      this.modals.selectedOrderId = order.id;
      this.modals.showUsedMaterialsModal = true;
    },

    hideUsedMaterialsModal() {
      this.modals.showUsedMaterialsModal = false;
      this.fetchAndSetOrders(this.currentPage);
      this.modals.selectedOrderId = null;
      this.modals.selectedOrder = null;
    },

    openOrderDetailsModal(orderRow) {
      this.modals.selectedOrderDetailsId = orderRow.id;
      this.modals.selectedOrderNum = orderRow.order_number;
      this.modals.showOrderDetailsModal = true;
    },

    hideOrderDetailsModal() {
      this.modals.showOrderDetailsModal = false;
      this.fetchAndSetOrders(this.currentPage);
      this.modals.selectedOrderDetailsId = this.modals.selectedOrderNum = null;
    },

    openOrderResultsModal() {
      this.modals.showOrderResultsModal = true;
    },

    hideOrderResultsModal() {
      this.modals.showOrderResultsModal = false;
    },

    onFilterApplied() {
      this.fetchAndSetOrders();
    },

    clearFilters() {
      this.searchTerm = '';
      this.customer = this.creator = this.status = this.deliveredDateRange = this.invoicedDateRange = null;
      this.fetchAndSetOrders();
    },

    filterCustomers(option, _label, search) {
      const lowerSearch = search.toLowerCase();
      return (
        (option.name || '').toLowerCase().includes(lowerSearch) ||
        (option.customer_number || '').toLowerCase().includes(lowerSearch)
      );
    },

    filterCreators(option, _label, search) {
      const lowerSearch = search.toLowerCase();
      return (
        (option.first_name || '').toLowerCase().includes(lowerSearch) ||
        (option.last_name || '').toLowerCase().includes(lowerSearch)
      );
    },

    truncateSheetNums(sheetNum) {
      return truncate(sheetNum, { length: 30, separator: /[, ?+]/ });
    },

    async fetchCustomers(params = {}) {
      this.areCustomersLoading = true;
      const response = await this.getAllCustomers({
        ordering: 'name',
        ...params,
      });
      this.allCustomers = response.data;
      this.areCustomersLoading = false;
    },

    async fetchCreators(params = {}) {
      this.areCreatorsLoading = true;
      const response = await this.getAllUsers({
        ordering: 'first_name,last_name',
        ...params,
      });
      this.allCreators = response.data;
      this.areCreatorsLoading = false;
    },

    async fetchDeliveryNotes() {
      this.isDelNoteLoading = true;
      this.deliveryNotes = [];
      this.deliveryNotesByOrder = {};

      if (this.totalOrders) {
        try {
          this.deliveryNotes = (
            await this.getOrdersDeliveryNotes({
              order_ids: this.allOrders.map((order) => order.id),
            })
          ).data;
          this.deliveryNotesByOrder = keyBy(this.deliveryNotes, 'order');
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.isDelNoteLoading = false;
    },

    async fetchInvoices() {
      this.areInvoicesLoading = true;
      this.invoices = [];
      this.invoicesByOrder = {};

      if (this.totalOrders) {
        try {
          this.invoices = (
            await this.getOrdersInvoices({
              order_ids: this.allOrders.map((order) => order.id),
            })
          ).data;
          this.invoicesByOrder = keyBy(this.invoices, 'order');
        } catch (err) {
          this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
        }
      }

      this.areInvoicesLoading = false;
    },

    async fetchAndSetOrders(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllOrders({
        ...(this.pageSize && {
          limit: this.pageSize,
          offset: (pageNum - 1) * this.pageSize,
        }),
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.customer && { customer: this.customer }),
        ...(this.creator && { created_by: this.creator }),
        ...(this.status && { status: this.status }),
        ...(this.deliveredDateRange &&
          this.deliveredDateRange.some((element) => element !== null) && {
            delivered_date_range: this.deliveredDateRange.join(),
          }),
        ...(this.invoicedDateRange &&
          this.invoicedDateRange.some((element) => element !== null) && {
            invoiced_date_range: this.invoicedDateRange.join(),
          }),
        ...params,
      });

      const orders = this.pageSize ? response.data.results : response.data;

      // Adding in revenue, cost and other lazy loaded keys.
      orders.forEach((order) => {
        order['revenue'] = (order.material_revenue + order.labor_revenue) * order.revenue_factor;
        order['cost'] = null;
      });

      this.allOrders = orders;
      this.currentPage = pageNum;
      this.totalOrders = this.pageSize ? response.data.count : get(orders, 'length', 0);
      this.isLoading = false;

      // this.fetchOrdersCosts();
      this.fetchDeliveryNotes();
      this.fetchInvoices();
    },

    async fetchOrdersCosts() {
      this.isCostLoading = true;

      const costs = (
        await this.getOrdersCosts({
          orderIds: this.allOrders.map((order) => order.id),
        })
      ).data;
      const costByOrder = keyBy(costs, 'order_id');

      this.allOrders.forEach((order) => {
        order['cost'] = get(costByOrder, `${order.id}.total_cost`, 0);
      });

      this.isCostLoading = false;
    },

    onPerPageChange() {
      this.fetchAndSetOrders();
    },

    onPageChange(pageNum) {
      this.fetchAndSetOrders(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAndSetOrders();
    },

    onSearch() {
      this.debouncedSearchOrders(this);
    },

    debouncedSearchOrders: debounce((vm) => {
      vm.fetchAndSetOrders();
    }, 500),

    async confirmDeleteOrder(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('orderMsgs.deleteConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteOrder(id);
          const refreshPage = this.allOrders.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetOrders(refreshPage || 1);
          this.makeToast('success', this.$t('generalMsgs.deletedSuccess'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchAndSetOrders();
    this.fetchCustomers();
    this.fetchCreators();
  },

  computed: {
    ...mapGetters(['getLoggedInUser', 'getCurrLanguage']),

    lang() {
      return this.getCurrLanguage;
    },

    areFiltersApplied() {
      return (
        this.searchTerm ||
        this.creator ||
        this.customer ||
        (this.deliveredDateRange && this.deliveredDateRange.some((element) => element !== null)) ||
        (this.invoicedDateRange && this.invoicedDateRange.some((element) => element !== null)) ||
        this.status
      );
    },

    allStatusOptions() {
      return [
        { value: 'is_delivered', text: this.$t('orderStatusChoices.isDelivered') },
        { value: 'is_invoiced', text: this.$t('orderStatusChoices.isInvoiced') },
        { value: 'has_bills', text: this.$t('orderStatusChoices.hasBills') },
        { value: 'open', text: this.$t('orderStatusChoices.open') },
      ];
    },

    tableColumns() {
      return [
        {
          key: 'order_number',
          label: this.$t('orderMsgs.orderNumShort'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'customer__name',
          label: this.$t('customerMsgs.customer'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'cost_center__center_number',
          label: this.$t('costCenterMsgs.costCenter'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'construction_project',
          label: this.$t('orderMsgs.constructionProj'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'sheet_numbers',
          label: this.$t('orderMsgs.sheetNums'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'labor_hours',
          label: this.$t('orderMsgs.laborHrsShort'),
          tdClass: 'clickable-item',
          sortable: true,
        },

        // {
        //   key: "deliveryNote",
        //   label: this.$t("orderMsgs.deliveryNote"),
        //   tdClass: "clickable-item",
        // },
        {
          key: 'delivery_date',
          label: this.$t('deliveryNoteMsgs.dateDeliveredBrk'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'invoice__invoice_date',
          label: this.$t('orderMsgs.invoiceDateBrk'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        // {
        //   key: "revenue",
        //   label: this.$t("orderMsgs.revenue"),
        //   tdClass: "clickable-item",
        // },
        // {
        //   key: "cost",
        //   label: this.$t("orderMsgs.cost"),
        //   tdClass: "clickable-item",
        // },
        {
          key: 'received_date',
          label: this.$t('orderMsgs.receivedDateBrk'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        // {
        //   key: "createdBy",
        //   label: this.$t("createdBy"),
        //   tdClass: "clickable-item",
        // },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '130px' },
        },
      ];
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
