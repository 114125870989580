<template>
  <div class="container-fluid">
    <b-modal
      ref="order-used-materials-modal"
      size="lg"
      :title="$t('materialMsgs.manageMaterials')"
      scrollable
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="col-md-12 text-right">
            <a
              href="#"
              class="btn btn-sm btn-primary"
              @click.prevent="openPostUsedMaterialModal()"
            >
              {{ $t("addNew") }}
            </a>
          </div>
          <div class="mt-3">
            <div class="table-responsive">
              <b-table
                :fields="tableColumns"
                :items="allUsedMaterials"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
              >
                <template #cell(material)="data">
                  <strong> {{ data.item.material.material_name }} </strong>
                </template>

                <template #cell(unit_price)="data">
                  {{
                    data.item.unit_price ? $n(data.item.unit_price, "currency", "de-DE") : '--'
                  }}
                </template>

                <template #cell(quantity)="data">
                  {{ $n(data.item.quantity, "number", "de-DE") }}
                  <i>{{ data.item.material.unit }}</i>
                </template>

                <template #cell(created_at)="data">
                  {{ formatDateTime(data.item.created_at) }}
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data">
                  <a
                    href="#"
                    @click.prevent="openPostUsedMaterialModal(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('edit')"
                    class="mr-2"
                  >
                    <i class="far fa-edit text-info" />
                  </a>
                  <a
                    href="#"
                    @click.prevent="confirmDeleteUsedMaterial(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                v-if="totalUsedMaterials > pageSize"
                :value="currentPage"
                :total-rows="totalUsedMaterials"
                :per-page="pageSize"
                @change="onPageChange"
                align="center"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <post-used-material-modal
      :showModal.sync="modals.showPostUsedMaterialModal"
      :orderId="orderId"
      :order="order"
      :usedMaterialId="modals.selectedUsedMaterialId"
      @close="hidePostUsedMaterialModal"
      @closeUpdate="hideUpdatePostUsedMaterialModal"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { DEFAULT_PAGE_SIZE } from "../../../common/constants";
import { formatDateTime } from "../../../common/utils";
import PostUsedMaterialModal from "./PostUsedMaterialModal";

export default {
  name: "UsedMaterialListModal",

  props: {
    showModal: Boolean,
    orderId: Number,
    order: Object,
  },

  components: { PostUsedMaterialModal },

  data() {
    return {
      allUsedMaterials: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalUsedMaterials: 0,
      isLoading: false,

      modals: {
        showPostUsedMaterialModal: false,
        selectedUsedMaterialId: null,
      },
    };
  },

  methods: {
    ...mapActions(["getUsedMaterials", "deleteUsedMaterial"]),
    formatDateTime,

    hideModal() {
      this.$emit("close");
      this.allUsedMaterials = [];
      this.currentPage = 1;
      this.totalUsedMaterials = 0;
      this.isLoading = false;
    },

    async fetchUsedMaterials(pageNum = 1, params = {}) {
      this.isLoading = true;

      try {
        const response = await this.getUsedMaterials({
          limit: this.pageSize,
          offset: (pageNum - 1) * this.pageSize,
          order: this.orderId,
          ...params,
        });
        this.allUsedMaterials = response.data.results;
        this.currentPage = pageNum;
        this.totalUsedMaterials = response.data.count;
      } catch (err) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchUsedMaterials(pageNum);
    },

    onSearch(params) {
      this.fetchUsedMaterials(
        1,
        params.searchTerm ? { search: params.searchTerm } : {}
      );
    },

    async confirmDeleteUsedMaterial(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("orderMsgs.deleteUsedMatConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteUsedMaterial(id);
          const refreshPage =
            this.allUsedMaterials.length > 1
              ? this.currentPage
              : this.currentPage - 1;
          this.fetchUsedMaterials(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.deletedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    openPostUsedMaterialModal(usedMaterialId = null) {
      this.modals.selectedUsedMaterialId = usedMaterialId;
      this.modals.showPostUsedMaterialModal = true;
    },

    hidePostUsedMaterialModal() {
      this.modals.showPostUsedMaterialModal = false;
      this.modals.selectedUsedMaterialId = null;
    },

    hideUpdatePostUsedMaterialModal() {
      this.hidePostUsedMaterialModal();
      this.fetchUsedMaterials(this.currentPage);
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {
    tableColumns() {
      return [
        {
          key: "material",
          label: this.$t("materialMsgs.material"),
        },
        { key: "unit_price", label: this.$t("materialMsgs.unitPrice") },
        { key: "quantity", label: this.$t("orderMsgs.quantity") },
        { key: "created_at", label: this.$t("createdAt") },
        {
          key: "actions",
          tdClass: "text-right",
          thStyle: { minWidth: "80px" },
        },
      ];
    },
  },

  watch: {
    showModal(value) {
      if (value && this.orderId) {
        this.fetchUsedMaterials();
        this.$refs["order-used-materials-modal"].show();
      }
    },
  },
};
</script>
